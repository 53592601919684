<template>
  <div v-if="walletAddressRef">
    <el-alert
      class="connect-wallet-alert"
      :closable="false"
      v-if="displayContractMessage"
      effect="dark"
      type="warning"
      show-icon
    >
      <h2>{{ contractMessage }}</h2>
    </el-alert>
  </div>
  <div v-if="!walletAddressRef || !connectedToDesiredNetwork">
    <el-alert
      class="connect-wallet-alert"
      :closable="false"
      effect="dark"
      type="warning"
      show-icon
    >
      <h2>
        Please connect your wallet to the {{ desiredNetworkName }} network.
      </h2>
    </el-alert>
  </div>
</template>

<script>
  import {
    computed,
    defineComponent,
    ref,
    watch,
    onMounted,
    onBeforeMount,
  } from 'vue'
  import { useStore } from 'vuex'
  import { NETWORK_IDS, NETWORK_NAMES } from '@/constants/walletConstants'

  import EscalerasContractService from '@/services/EscalerasContractService'
  import { getEscalerasAllowListAddress } from '@/services/EscalerasService'

  export default defineComponent({
    name: 'MintButton',
    components: {},
    emits: ['setMintingStep'],
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user, mintingExperience },
      } = store

      const web3 = computed(() => contractState.web3)
      const contract = computed(() => contractState.contract)
      const walletAddress = computed(() => user.walletAddress)
      const mintingStep = computed(() => mintingExperience.mintingStep)
      const networkId = computed(() => user.networkId)

      const supplyLeft = ref(0)
      const isPaused = ref(false)
      const allowListMinting = ref(false)
      const publicMinting = ref(false)
      const displayContractMessage = ref(false)
      const contractMessage = ref('')

      const contractRef = ref(contract)
      const walletAddressRef = ref(walletAddress)
      const connectedToDesiredNetwork = ref(false)
      const desiredNetworkName = ref('')

      const retrieveContractInformation = async () => {
        await dispatch('setLoading', true)
        const escalerasContractService = new EscalerasContractService(
          web3.value,
          contract.value,
          true,
          store
        )
        const contractInformation = await escalerasContractService.readContract(
          walletAddress.value
        )

        supplyLeft.value = Number.parseInt(contractInformation.supplyLeft)
        isPaused.value = contractInformation.isPaused
        allowListMinting.value = contractInformation.allowListMinting
        publicMinting.value = contractInformation.publicMinting

        if (supplyLeft.value <= 0) {
          displayContractMessage.value = true
          contractMessage.value =
            'Escaleras is Sold Out. Please browse the gallery to see the mints.'
        } else if (isPaused.value) {
          displayContractMessage.value = true
          contractMessage.value =
            'Escaleras minting is currently paused. Please check back soon.'
        } else if (allowListMinting.value && !publicMinting.value) {
          const whitelistedAddress = await (
            await getEscalerasAllowListAddress(walletAddress.value)
          ).response

          if (whitelistedAddress) {
            displayContractMessage.value = false
          } else {
            contractMessage.value =
              'This address is not in the Allow List. Please connect with an address on the Allow List or wait for Public minting to start.'
            displayContractMessage.value = true
          }
        } else {
          displayContractMessage.value = false
        }

        await dispatch('setLoading', false)
      }

      onBeforeMount(() => {
        if (web3.value) {
          connectedToDesiredNetwork.value =
            networkId.value &&
            NETWORK_IDS[process.env.NODE_ENV] == networkId.value
          desiredNetworkName.value =
            NETWORK_NAMES[NETWORK_IDS[process.env.NODE_ENV]]
        }
      })

      onMounted(() => {
        if (contractRef.value) {
          retrieveContractInformation()
        }
      })

      watch([contractRef, walletAddressRef, networkId], () => {
        if (contractRef.value && walletAddressRef.value) {
          retrieveContractInformation()
        }
        connectedToDesiredNetwork.value =
          networkId.value &&
          NETWORK_IDS[process.env.NODE_ENV] == networkId.value
        desiredNetworkName.value =
          NETWORK_NAMES[NETWORK_IDS[process.env.NODE_ENV]]
      })

      return {
        isPaused,
        mintingStep,
        walletAddressRef,
        supplyLeft,
        connectedToDesiredNetwork,
        desiredNetworkName,
        displayContractMessage,
        contractMessage,
      }
    },
  })
</script>
<style scoped>
  .connect-wallet-alert {
    width: fit-content !important;
    margin: 30px auto 0 auto !important;
  }
</style>
