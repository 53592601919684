import { db } from '@/firebase'
import axios from 'axios'
import { ESCALERAS_ALLOW_LIST_COLLECTION } from '@/constants/collections'

const escalerasAllowListRef = db.collection(ESCALERAS_ALLOW_LIST_COLLECTION)

export const getEscalerasAllowListAddress = async (walletAddress) => {
  let response
  let error
  try {
    const snapshot = await escalerasAllowListRef
      .where('walletAddress', '==', walletAddress.toLowerCase())
      .limit(1)
      .get()
    response = snapshot.docs[0].data()
  } catch (e) {
    error = e
  }

  return { response, error }
}

export async function getEscalerasAllowListSignature(walletAddress) {
  try {
    const params = {
      walletAddress: walletAddress,
    }
    console.log(process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL)
    const signatureResponse = await axios.get(
      process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL +
        '/api/getAllowListEscalerasSignature',
      { params }
    )
    console.log('signatureResponse', signatureResponse)
    return signatureResponse.data
  } catch (e) {
    console.error('Error getting signature for allow list.')
    throw e
  }
}
