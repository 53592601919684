<template>
  <div v-if="walletAddressRef">
    <div v-if="isPaused || totalSupply <= 0">
      <el-button type="success" :disabled="true" style="border: none">
        {{ isPaused ? 'Contract Paused' : 'Sold Out' }}
      </el-button>
    </div>
    <div v-else>
      <el-button
        type="success"
        v-if="walletAddressRef && connectedToDesiredNetwork && publicMinting"
        :disabled="isPaused"
        @click="doMinting()"
      >
        Mint {{ formatPublicPrice() }}
      </el-button>
      <el-button
        type="success"
        v-if="
          walletAddressRef &&
          connectedToDesiredNetwork &&
          !publicMinting &&
          allowListMinting
        "
        :disabled="isPaused || !isWhitelisted"
        @click="doAllowListMinting()"
      >
        Mint {{ formatAllowListPrice() }}
      </el-button>
    </div>
  </div>
  <div v-if="!walletAddressRef || !connectedToDesiredNetwork">
    <el-button
      type="success"
      style="background-color: rgb(221, 149, 15); border: none"
      :disabled="true"
    >
      {{
        !walletAddressRef
          ? 'Connect to Mint'
          : 'Connect to the ' + desiredNetworkName + ' network to Mint'
      }}
    </el-button>
  </div>
</template>

<script>
  import {
    computed,
    defineComponent,
    ref,
    watch,
    onMounted,
    onBeforeMount,
  } from 'vue'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'
  import { NETWORK_IDS, NETWORK_NAMES } from '@/constants/walletConstants'

  import EscalerasContractService from '@/services/EscalerasContractService'
  import {
    getEscalerasAllowListAddress,
    getEscalerasAllowListSignature,
  } from '@/services/EscalerasService'

  export default defineComponent({
    name: 'MintButton',
    components: {},
    emits: ['setMintingStep'],
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user, root, mintingExperience },
      } = store

      const web3 = computed(() => contractState.web3)
      const contract = computed(() => contractState.contract)
      const walletAddress = computed(() => user.walletAddress)
      const selectedProject = computed(() => root.selectedProject)
      const mintingStep = computed(() => mintingExperience.mintingStep)
      const networkId = computed(() => user.networkId)

      const publicPrice = ref('')
      const allowListPrice = ref('')
      const supplyLeft = ref(0)
      const isPaused = ref(false)
      const allowListMinting = ref(false)
      const publicMinting = ref(false)
      const isWhitelisted = ref(false)

      const contractRef = ref(contract)
      const walletAddressRef = ref(walletAddress)
      const notificationTime = process.env.VUE_APP_NOTIFICATION_DURATION
      const connectedToDesiredNetwork = ref(false)
      const desiredNetworkName = ref('')

      const doMinting = async () => {
        dispatch('setLoading', true)
        const escalerasContractService = new EscalerasContractService(
          web3.value,
          contract.value,
          true,
          store
        )

        let result = null

        const params = {
          price: publicPrice.value,
          contractAddress: selectedProject.value?.configuration.contractAddress,
          walletAddress: walletAddress.value,
        }

        try {
          result = await escalerasContractService.publicMint(params)
          dispatch('setMintedQty', 1)
          dispatch('setMintingStep', 2)
          console.log('result: ', result)
          ElNotification({
            title: 'Success',
            message: 'Minting successful.',
            type: 'success',
            duration: notificationTime,
          })

          await retrieveContractInformation()
        } catch (e) {
          if (e.code === 4001) {
            ElNotification({
              title: 'Error',
              message: 'Minting cancelled.',
              type: 'error',
              duration: notificationTime,
            })
          } else if (e.code === -32603) {
            ElNotification({
              title: 'Error',
              message: 'Error processing TX.',
              type: 'error',
              duration: notificationTime,
            })
          } else {
            ElNotification({
              title: 'Error',
              message: `Minting failed: ${e.message}`,
              type: 'error',
              duration: notificationTime,
            })
          }
        }

        dispatch('setLoading', false)
      }

      const doAllowListMinting = async () => {
        dispatch('setLoading', true)
        const escalerasContractService = new EscalerasContractService(
          web3.value,
          contract.value,
          true,
          store
        )

        let result = null

        const allowListSignature = await getEscalerasAllowListSignature(
          walletAddress.value
        )

        const params = {
          price: allowListPrice.value,
          contractAddress: selectedProject.value?.configuration.contractAddress,
          walletAddress: walletAddress.value,
          signature: allowListSignature.response.signature,
        }

        try {
          result = await escalerasContractService.allowListMint(params)
          dispatch('setMintedQty', 1)
          dispatch('setMintingStep', 2)
          console.log('result: ', result)
          ElNotification({
            title: 'Success',
            message: 'Minting successful.',
            type: 'success',
            duration: notificationTime,
          })

          await retrieveContractInformation()
        } catch (e) {
          if (e.code === 4001) {
            ElNotification({
              title: 'Error',
              message: 'Minting cancelled.',
              type: 'error',
              duration: notificationTime,
            })
          } else if (e.code === -32603) {
            ElNotification({
              title: 'Error',
              message: 'Error processing TX.',
              type: 'error',
              duration: notificationTime,
            })
          } else {
            ElNotification({
              title: 'Error',
              message: `Minting failed: ${e.message}`,
              type: 'error',
              duration: notificationTime,
            })
          }
        }

        dispatch('setLoading', false)
      }

      const setPrice = (contractInformation) => {
        publicPrice.value = contractInformation.publicPrice
        allowListPrice.value = contractInformation.allowListPrice
      }

      const formatPublicPrice = () => {
        if (publicPrice.value === '') {
          return ''
        }

        return publicPrice.value === 0 ? '(free)' : `(${publicPrice.value} eth)`
      }

      const formatAllowListPrice = () => {
        if (allowListPrice.value === '') {
          return ''
        }

        return allowListPrice.value === 0
          ? '(free)'
          : `(${allowListPrice.value} eth)`
      }

      const retrieveContractInformation = async () => {
        await dispatch('setLoading', true)
        const escalerasContractService = new EscalerasContractService(
          web3.value,
          contract.value,
          true,
          store
        )

        const contractInformation = await escalerasContractService.readContract(
          walletAddress.value
        )

        setPrice(contractInformation)
        supplyLeft.value = Number.parseInt(contractInformation.supplyLeft)
        isPaused.value = contractInformation.isPaused
        allowListMinting.value = contractInformation.allowListMinting
        publicMinting.value = contractInformation.publicMinting

        if (allowListMinting.value && !publicMinting.value) {
          isWhitelisted.value = await (
            await getEscalerasAllowListAddress(walletAddress.value)
          ).response
        }

        await dispatch('setLoading', false)
      }

      onBeforeMount(() => {
        if (web3.value) {
          connectedToDesiredNetwork.value =
            networkId.value &&
            NETWORK_IDS[process.env.NODE_ENV] == networkId.value
          desiredNetworkName.value =
            NETWORK_NAMES[NETWORK_IDS[process.env.NODE_ENV]]
        }
      })

      onMounted(() => {
        if (contractRef.value) {
          retrieveContractInformation()
        }
      })

      watch([contractRef, walletAddressRef, networkId], () => {
        if (contractRef.value && walletAddressRef.value) {
          retrieveContractInformation()
        }
        connectedToDesiredNetwork.value =
          networkId.value &&
          NETWORK_IDS[process.env.NODE_ENV] == networkId.value
        desiredNetworkName.value =
          NETWORK_NAMES[NETWORK_IDS[process.env.NODE_ENV]]
      })

      return {
        isPaused,
        mintingStep,
        walletAddressRef,
        supplyLeft,
        connectedToDesiredNetwork,
        desiredNetworkName,
        allowListMinting,
        publicMinting,
        isWhitelisted,
        doMinting,
        doAllowListMinting,
        formatPublicPrice,
        formatAllowListPrice,
      }
    },
  })
</script>
