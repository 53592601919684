<template>
  <div class="escaleras">
    <MintingExperience projectSlug="escaleras" />
    <div v-if="mintingStep < 1" class="main-container">
      <div v-if="mintedTokens.length > 0">
        <el-row style="margin: 10px 0px 10px">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="title">EXAMPLE MINTS</div>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 5px; padding: 1%" :gutter="20">
          <el-col
            :xs="24"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
            v-for="(token, index) in mintedTokens"
            :key="index"
            style="margin: 0 0 1% 0"
            :class="index >= 1 ? 'hide-on-small' : ''"
          >
            <PalabrasMintTokenCard :tokenData="token" />
          </el-col>
        </el-row>
        <hr class="escaleras-divider" />
      </div>
      <el-row>
        <el-col>
          <div class="grid-content bg-purple-light"></div>
          <ContractMessage />
          <div class="s1-project-btns">
            <div class="desktop-mint-btn-container">
              <MintButton />
            </div>
            <el-link href="/tokens/escaleras" :underline="false">
              <el-button type="primary">
                Browse Gallery
                <el-icon class="el-icon--right"><Grid /></el-icon>
              </el-button>
            </el-link>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { Grid } from '@element-plus/icons'
  import { computed, ref, onBeforeMount, onBeforeUnmount, watch } from 'vue'
  import { useStore } from 'vuex'
  import MintButton from '@/components/projects/escaleras/escaleras-mint/mint-button'
  import ContractMessage from '@/components/projects/escaleras/escaleras-mint/contract-message'
  import MintingExperience from '@/components/minting-experience/'
  import PalabrasMintTokenCard from '@/components/projects/palabras/palabras-mint-token-card/'
  import { getProjectTokens } from '@/services/TokenService'

  import { selectProjectContract } from '@/services/ProjectService'

  export default {
    name: 'BaseGenArt',

    components: {
      ContractMessage,
      MintButton,
      MintingExperience,
      PalabrasMintTokenCard,
      Grid,
    },

    setup() {
      // Hooks
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user, mintingExperience },
      } = store

      // Read from state
      const addressState = computed(() => user.walletAddress)
      const web3 = computed(() => contractState.web3)
      const mintingStep = computed(() => mintingExperience.mintingStep)

      const addressStateRef = ref(addressState)

      const mintedTokens = ref([])

      onBeforeMount(async () => {
        if (web3.value) {
          await selectProjectContract('escaleras', web3.value, dispatch)
        }
        dispatch('setMintingStep', 0)
        const tokenIndexes = []
        const escalerasTokens = (
          await getProjectTokens('escaleras', 'tokenID', 'asc')
        ).response
        const tokensWithImage = escalerasTokens.filter((t) => t.image)
        if (tokensWithImage.length > 3) {
          for (let i = 0; i < 3; i++) {
            let n = Math.floor(Math.random() * (tokensWithImage.length - 1) + 1)
            if (tokenIndexes.includes(n)) {
              i = i - 1
            } else {
              tokenIndexes.push(n)
              mintedTokens.value.push(tokensWithImage[n])
            }
          }
        }
      })

      onBeforeUnmount(() => {
        dispatch('setMintingStep', 0)
      })

      watch([web3], async () => {
        await selectProjectContract('escaleras', web3.value, dispatch)
      })

      return {
        addressStateRef,
        mintedTokens,
        mintingStep,
      }
    },
  }
</script>

<style scoped>
  .escaleras {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-container {
    max-width: 1500px;
    overflow: hidden;
  }

  .left-container,
  .right-container {
    padding: 2px;
    width: 100%;
    height: 100%;
  }

  .s1-project-btns {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .desktop-mint-btn-container {
    margin: 20px;
  }

  .title {
    font-weight: bolder;
    font-size: 30px;
    text-align: left;
    white-space: nowrap;
  }
  .escaleras-divider {
    margin: 1% 0% 1% 0%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #bf953f,
      #fcf6ba,
      #b38728,
      #fbf5b7,
      #aa771c
    );
  }
</style>
